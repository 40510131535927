@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;700&display=swap");

.modal-position {
  width: 100%;
  height: 100%;
  position: absolute;
  background: transparent;
  left: 0;
}
.content-centered-modal-cadastro {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center; /* Centraliza o conteúdo horizontalmente */
  height: 100%;
  width: 100%;
}
.title-modal-cadastro,
.subtitle-modal-cadastro {
  font-family: "Bricolage Grotesque";
  font-size: 16px;
  text-align: center;
}
.title-modal-cadastro {
  font-weight: 700;
}
.subtitle-modal-cadastro {
  font-weight: 400;
}

.button-modal-container-email {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.error-mgs-email-modal-cadastro {
  font-family: "Kumbh Sans";
  font-size: 12px;
  font-weight: 400;
  color: red;
  width: 260px;
  margin-top: 2px;
}

.container-children-installation {
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

.modal-container {
  /* border: 1px solid red; */
  position: fixed;
  top: 0;
  right: 0;
  width: 100%; /* Ajuste a largura conforme necessário */
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Fundo cinza transparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Certifique-se de que o modal esteja acima do conteúdo existente */
}

.modal-content {
  /* border: 1px solid red; */
  position: relative; /* Adicione esta linha para o posicionamento relativo em relação à .modal-container */
  width: 100%;
  /* height: fit-content; */
  background-color: #fff;
  /* padding: 20px; */
  border-radius: 8px;
}
.container-input {
  width: 100%;
  /* width: 280px; */
  background: var(--Primary-White, #fff);
  border-radius: 14px;
  height: 56px;
  position: relative;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
}
.modal-email-cadastro {
  /* border: 1px solid blue; */
  position: relative;
  min-width: 70%;
  max-width: 80%;
  background-color: #fff;
  border-radius: 8px;
}

.close-icon {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  font-size: 20px;
  color: #000;
}

.centered-content {
  object-fit: cover;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  align-items: center;
  justify-content: center;
  text-align: center; /* Centraliza o conteúdo horizontalmente */
  height: 100%; /* Garante que a altura ocupe 100% do contêiner da modal */
}
.modal-titular {
  /* border: 1px solid red; */
  position: relative;
  width: 95%;
  height: 80%;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 400ms ease;
}

.modal-titular-content {
  /* border: 1px solid red; */
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  margin-top: 1.5rem;
  /* margin-top: 24px; */
  align-items: center;
  justify-content: center;
  text-align: center; /* Centraliza o conteúdo horizontalmente */
}
@media screen and (min-width: 641px) {
  .modal-titular {
    width: 75%;
    height: 80%;
  }
  .modal-email-cadastro {
    /* border: 1px solid blue; */

    min-width: 50%;
    max-width: 65%;
  }
}

@media screen and (min-width: 760px) {
  .modal-titular {
    width: 55%;
    height: 80%;
  }
  .modal-email-cadastro {
    /* border: 1px solid purple; */
    min-width: 50%;
    max-width: 55%;
    padding: 2rem;
  }
}

@media screen and (min-width: 1008px) {
  .modal-container {
    /* border: 1px solid blue; */
    position: fixed;
    top: 0;
    right: 0;
    width: 50%; /* Ajuste a largura conforme necessário */
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Fundo cinza transparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Certifique-se de que o modal esteja acima do conteúdo existente */
  }
  .modal-titular {
    /* border: 1px solid green; */
    width: 38vw;
    height: 80vh;
    /* width: 80%;
    max-height: 80%; */
  }
  .modal-content {
    /* border: 1px solid blue; */
    position: relative; /* Adicione esta linha para o posicionamento relativo em relação à .modal-container */
    width: 95%;
    height: fit-content;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
  }
  .modal-email-cadastro {
    /* border: 1px solid green; */
    position: relative;
    /* width: 23.3rem; */
    min-width: 50%;
    max-width: 70%;
    border-radius: 8px;
    padding: 1rem;
  }
  .container-input {
    width: 280px;
    background: var(--Primary-White, #fff);
    border-radius: 14px;
    height: 56px;
    position: relative;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    margin: 1.5rem 0 0.5rem 0;
  }
  .content-centered-modal-cadastro {
    height: fit-content;
    margin: 1.5rem 0 0.8rem 0;
    justify-content: center;
  }
  .button-modal-container-email {
    width: 90%;
  }
}

@media screen and (min-height: 900px) {
  .modal-titular {
    /* border: 1px solid blue; */
    width: 30vw;
    height: 60vh;
    /* max-width: 60%;
    max-height: 60%; */
  }
}
