.card-simple {
  /* border: 1px solid blue; */
  /* background-color: #ffffff; */
  width: 100%;
  border-radius: 14px;
  height: 100%;
  margin-bottom: 13px;
  /* box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1); */
}
@media screen and (max-width: 1008px) {
  #container.container-term {
    height: 500px;
  }
  
}
.container-termo-adesao {
  background-color: #ffffff;
  /* border: 1px solid red; */
  border-radius: 14px 14px 0 0;
  /* margin-bottom: 13px; */
  height: 3rem;
  display: flex;
  align-items: center;
}
.title-termo-adesao {
  /* border: 1px solid blue; */
  font-weight: bold;
  margin-left: 1rem;
}
#container {
  /* border: 1px solid blue; */
  width: 100%;
  height: 100%;
  margin-bottom: 1rem;
}

.container-term {
  /* border: 1px solid green; */
  width: 90%;
  height: 90%;
}
