.custom-card-confirm-data {
  /* border: 1px solid red; */
  /* max-width: 434px; */

  width: 100%;
  max-height: 202px;
  background-color: #ffffff;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  border-radius: 14px;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
}

.title-container-confirm-data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.title-confirm-data {
  font-weight: bold;
  color: black;
  font-size: 18px;
}

.edit-icon-confirm-data {
  /* border: 1px solid red;s */
  cursor: pointer;
  margin-right: -6px;
}
.data-container-confirm {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  overflow-y: scroll;
  /* gap: 16px; */
}
.data-container-confirm::-webkit-scrollbar {
  width: 7px;
}
.data-container-confirm::-webkit-scrollbar-track {
  background: transparent;
}
.data-container-confirm::-webkit-scrollbar-thumb {
  border-radius: 3.5px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: rgba(0, 0, 0, 0.5);
}

@media screen and (min-width: 428px) {
  .custom-card-confirm-data {
    padding: 10px 18px;
  }
}

@media screen and (min-width: 641px) {
  .custom-card-confirm-data {
    width: 95%;
    /* min-width: 28rem; */
    /* height: 202px; */
  }
}

@media screen and (min-width: 1008px) {
  .custom-card-confirm-data {
    width: 100%;
    /* min-height: 109px; */
    max-height: 202px;
  }

  .title-container-confirm-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2px;
  }

  .title-confirm-data {
    font-weight: bold;
    color: black;
    font-size: 18px;
  }

  .edit-icon-confirm-data {
    cursor: pointer;
    margin-right: -6px;
  }
  .data-container-confirm {
    display: flex;
    gap: 16px;
    /* flex-wrap: nowrap; */
    /* justify-content: flex-start; */
  }
}

@media screen and (min-width: 1224px) {
  .custom-card-confirm-data {
    min-height: 109px;
    height: 202px;
    justify-content: center;
  }
}
