@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;700&display=swap");

.section-datas {
  /* border: 1px solid blue; */
  width: 100%;
  display: grid;
  grid-template-areas:
    "name-div name-div nacionalite-div "
    "cpf-div rg-div marital-status-div";

  font-family: "Kumbh Sans";
  /* font-size: 12px; */
  gap: 0.5rem;
  transition: all 0.5s ease;
}
#name-div {
  grid-area: name-div;
}
#nacionalite-div {
  grid-area: nacionalite-div;
}
#cpf-div {
  grid-area: cpf-div;
}
#rg-div {
  grid-area: rg-div;
}
#marital-status-div {
  grid-area: marital-status-div;
}

.section-holder-cnpj {
  /* border: 1px solid red; */
  display: flex;
  gap: 2rem;
}

.label-data-holder {
  color: #727272;
  font-family: "Kumbh Sans";
  font-size: 14px;
  align-items: flex-start;
  white-space: nowrap;
}

.value-data-holder-name {
  /* border: 1px solid red; */
  width: 100%;
  font-family: "Kumbh Sans";
  font-size: 16px;
}

.value-data-holder {
  /* border: 1px solid blue; */
  font-family: "Kumbh Sans";
  font-size: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

@media screen and (min-width: 641px) {
  .section-datas-holder {
    gap: 1rem;
  }
  .section-holder-cnpj {
    /* border: 1px solid blue; */
    display: flex;
    gap: 3.5rem;
  }
}
@media screen and (min-width: 768px) {
  .section-datas {
    grid-template-areas: "name-div nacionalite-div cpf-div rg-div marital-status-div ";
  }
}

@media screen and (min-width: 855px) {
  .section-datas {
    grid-template-areas: "name-div nacionalite-div cpf-div rg-div marital-status-div ";
  }
}

@media screen and (min-width: 1008px) {
  .section-datas {
    font-family: "Kumbh Sans";
    /* font-size: 14px; */

    grid-template-areas:
      "name-div name-div nacionalite-div "
      "cpf-div rg-div marital-status-div";
  }
}

@media screen and (min-width: 1194px) {
  .section-datas {
    grid-template-areas: "name-div nacionalite-div cpf-div rg-div marital-status-div ";
    gap: 1rem;
  }
}

@media screen and (min-width: 1440px) {
  .section-holder-cnpj {
    /* border: 1px solid red; */
    display: flex;
    gap: 3.5rem;
  }
}
