.main-home {
  position: absolute;
  width: 100vw;
  height: 100%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}
@media screen and (min-width: 1008px) {
  .main-home {
    overflow-y: hidden;
  }
}
