@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:wght@400;700&display=swap");

.container-screen-error {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.title-screen-error {
  font-size: 20px;
  font-weight: 700;
  font-family: "Bricolage Grotesque";
  text-align: center;
}
.description-screen-error {
  font-size: 14px;
  font-weight: 400;
  font-family: "Bricolage Grotesque";
  width: 100%;
  text-align: center;
}
.strong-saiba-mais {
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (min-width: 641px) {
  .container-screen-error {
    /* border: 1px solid red; */
    justify-content: center;
    padding: 0;
  }

  .title-screen-error {
    font-size: 24px;
  }
  .description-screen-error {
    font-size: 16px;
    width: 576px;
  }
}
