.centered-card {
  width: 17.5rem;
  height: 12.3rem;
  /* width: 300px; 
  height: 200px;  */
  padding: 46px 27px;
  background-color: #ffffff;
  /* border: 1px solid #000000; */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px; /* Ajuste o raio da borda conforme desejado */
  text-align: center; /* Centraliza o texto */
  /* margin-bottom: 16px; Adicione margem conforme necessário */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.file-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 18%;
  overflow: hidden;

  & > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    width: 70%;
  }

  & > svg {
    cursor: pointer;
    transition: all 300ms ease;

    &:hover {
      transform: translateY(-3px);
    }
  }
}

.icon-bin {
  margin-left: 6px;
}
