.container-loading {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
}
