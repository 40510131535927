@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;700&display=swap");
.content-modal-installation-registered {
  /* border: 1px solid red; */
  text-align: center;
  height: 124px;
  width: 100%;
}
.content-installation {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.title-modal-installation-registered,
.subtitle-modal-installation-registered {
  font-size: 16px;
  text-align: center;
}
.title-modal-installation-registered {
  font-weight: 700;
}
.subtitle-modal-installation-registered {
  font-weight: 400;
  width: 271px;
}
