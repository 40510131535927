@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;700&display=swap");

.disabled {
  opacity: 0.7; /* Defina a opacidade desejada quando o botão estiver desativado */
  /* Outras propriedades de estilo desejadas */
}

.continue-button {
  width: 114px;
  height: 48px;

  border: none;
  border-radius: 12px;
  box-shadow: 0px 10px 40px -12px #ffffff52;
  background: var(--main-color);
  outline: none;

  font-family: "Kumbh Sans";
  font-size: 14px;
  color: var(--continue-button-font);
  letter-spacing: 1px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  transition: all 400ms ease;
}
.back-button {
  height: 48px;
  width: 94px;

  border: 1px solid var(--back-button-color); /* Alterado para adicionar uma borda sólida */
  border-radius: 12px;
  box-shadow: 0px 10px 40px -12px #ffffff52;
  outline: none;
  letter-spacing: 1px;
  background: #fcfcfc;

  font-family: "Kumbh Sans";
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  color: var(--back-button-color);
  cursor: pointer;
}
