@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;700&display=swap");

.section-datas-contact {
  /* border: 1px solid blue; */
  width: 100%;
  display: grid;
  grid-template-areas:
    "name-contact-div name-contact-div name-contact-div "
    "email-contact-div phone-contact-div phone-contact-div";
  gap: 1rem;

  font-family: "Kumbh Sans";
  font-size: 12px;
}

#name-contact-div {
  grid-area: name-contact-div;
  /* width: 196px; */
}
#email-contact-div {
  grid-area: email-contact-div;
}
#phone-contact-div {
  grid-area: phone-contact-div;
}

.label-data-contact {
  color: #727272;
  font-family: "Kumbh Sans";
  font-size: 14px;
}
.value-contact-email {
  /* border: 1px solid red; */
  font-family: "Kumbh Sans";
  font-size: 16px;
  width: 100%;
  word-break: break-all;
  /* word-wrap: break-word; */
  /* text-size-adjust: auto; */
}

.value-contact {
  /* border: 1px solid green; */
  font-family: "Kumbh Sans";
  font-size: 16px;
  width: 100%;
}

.text-smash-email {
  /* border: 1px solid purple; */

  font-family: "Kumbh Sans";
  font-size: 12px;
  justify-content: flex-start;
}

@media screen and (min-width: 428px) {
  .section-datas-contact {
    grid-template-areas:
      "name-contact-div email-contact-div "
      "phone-contact-div phone-contact-div";
  }
}

@media screen and (min-width: 641px) {
  .section-datas-contact {
    grid-template-areas: "name-contact-div name-contact-div email-contact-div phone-contact-div ";
    gap: 1rem;
  }
  /* .text-smash-email,
  .label-data-contact,
  .section-datas-contact {
    font-size: 14px;
  }
  .value-contact,
  .value-contact-email {
    font-size: 16px;
  } */
  .value-contact-email,
  .value-contact {
    width: 100%;
  }
}

/* @media screen and (min-width: 1008px) {
  .section-datas-contact {
    grid-template-areas:
      "name-contact-div email-contact-div "
      "phone-contact-div phone-contact-div";
  }
} */
@media screen and (min-width: 1440px) {
  .section-datas-contact {
    width: 90%;
  }
}
