.middle-content-pdf-error {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.title-pdf-error-validation {
  /* border: 1px solid blue; */
  font-size: 22px;
  font-weight: 700;
  font-family: "Bricolage Grotesque";
  margin: 1rem 0;
  text-align: center;
}

.text-pdf-error {
  max-width: 576px;
  line-height: 24px;
  text-align: center;
}

@media screen and (min-width: 641px) {
  .title-pdf-error-validation {
    font-size: 24px;
  }
}
