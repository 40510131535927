.container-distributor {
  /* border: 1px solid red; */
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0 2rem 0;
  gap: 3rem;
}
.container-inputs-distribuitor {
  /* border: 1px solid green; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}
/* .space {
  margin-top: 8px;
} */

.button-container-distributor {
  width: 220px;
  display: flex;
  justify-content: space-between;
}

.email-distributor-error-input {
  all: unset;
  color: red;
  font-size: 14px;
  width: 270px;
}

@media screen and (min-width: 1008px) {
  .container-distributor {
    /* border: 1px solid red; */
    height: 100%;
    justify-content: center;

    padding: 0;
  }
}
