.container-info-validation {
  /* margin: auto 0; */
  /* position: fixed; */
  /* transform: translateY(10vh); */
  /* top: 200px; */
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 30px 35px; */
}
.input-container {
  display: flex;
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  flex-direction: column;
  margin-bottom: 5px;
}

.inputs-insider-container {
  /* border: 1px solid red; */
  display: flex;
  gap: 0.3rem;
}
.custom-input-big {
  box-sizing: border-box;
  border: none;
  border-radius: 14px;
  outline: none;

  display: inline-flex;
  align-items: center;

  background: var(--Primary-White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);

  width: 3.1rem;
  height: 4.5rem;
  padding: 0.1rem 0;

  font-size: 2.5rem;
  font-family: "Kumbh Sans", sans-serif;
  color: #000000;
  font-weight: bold;
  text-align: center;
}

.score-container {
  /* border: 1px solid red; */
  width: 16rem;
  height: 23px;
  display: flex;
  justify-content: space-evenly; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  margin: 15px 0;
}

.text-Send {
  color: var(--Secondary-Extended-Light-Grey-2, #d9d9d9);
  font-family: "Kumbh Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 22.5px */
}
.text-send-error {
  color: #000000;
  text-decoration: underline;
  font-family: "Kumbh Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  cursor: pointer;
}

.error-mgs-validation {
  font-family: "Kumbh Sans";
  color: red;
  font-size: 14px;
  width: 260px;
  text-align: center;
  margin: 5px 0;
}

.button-container-validation {
  width: 220px;
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 641px) {
  .container-info-validation {
    /* border: 1px solid blue; */
    height: 100%;
    justify-content: space-between;
    padding: 5rem 5rem 20rem 5rem;
  }
  /* .input-container {
    border: 1px solid blue;
  } */
  .error-mgs-validation {
    width: 460px;
  }
  .custom-input-big {
    width: 5.2rem;
    height: 6.8rem;
    font-size: 4rem;
    padding: 0;
  }
  /* .button-container-validation {
    border: 1px solid blue;
  } */
}

@media screen and (min-width: 1008px) {
  .container-info-validation {
    /* border: 1px solid green; */
    justify-content: center;
    padding: 0;
  }
  .custom-input-big {
    width: 5.3rem;
    height: 6.8rem;
    font-size: 3.8rem;
    padding: 0;
  }
  .inputs-insider-container {
    gap: 0.5rem;
  }
}
