@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;700&display=swap");

.middle-content {
  /* border: 1px solid red; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
}
.title-almost-there {
  font-family: "Bricolage Grotesque";
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 8px;
  text-align: center;
  transition: all 0.5s ease;
}

.description-almost-there {
  font-family: "Bricolage Grotesque";
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  text-align: center;
  transition: all 0.5s ease;
}

.btn-back-almost-there {
  width: 192px;
  display: flex;

  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 641px) {
  .title-almost-there {
    font-size: 24px;
  }
  .description-almost-there {
    font-size: 16px;
    width: 576px;
  }
}
