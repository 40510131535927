@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;700&display=swap");

/* tela min-widht: 360px*/
.right-home {
  /* border: 1px solid blue; */
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #f8f8f8;
  padding: 2.2rem 1.5rem;
  transition: all 0.5s ease;
  overflow-y: auto;
}

.right-home::-webkit-scrollbar {
  width: 7px;
}
.right-home::-webkit-scrollbar-track {
  background: transparent;
}
.right-home::-webkit-scrollbar-thumb {
  border-radius: 3.5px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: rgba(0, 0, 0, 0.5);
}

.header-mobile {
  /* border: 1px solid red; */

  display: flex;
  justify-content: space-between;
  width: 100%;
}

.header-desktop {
  /* border: 1px solid blue; */
  display: none;
}

.logo-ivi-mobile {
  width: 100px;
  cursor: pointer;
  /* position: fixed;
  top: 40px;
  left: 35px; */
}

.question-lado-direito {
  cursor: pointer;
  /* position: fixed;
  top: 40px;
  right: 30px; */
}

.container-title-description-mobile-version {
  /* border: 1px solid red; */
  width: 100%;
  margin: 1rem 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}
.description-mobile-right {
  /* border: 1px solid red; */
  font-family: "Bricolage Grotesque";
  font-size: 14px;
  width: 80%;
}

.h3-custom-mobile {
  color: var(--Primary-Black, #000);
  font-family: "Bricolage Grotesque";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.logo-energiav {
  width: 60px;
  z-index: 999;
}

.logo-Ivi {
  width: 70px;
  z-index: 999;
  object-fit: cover;
  object-position: center;
}

.card-home {
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 30px 35px; */
}
.container-termo-adesao-mobile {
  /* border: 1px solid blue; */
  margin: 2rem 0 0.5rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.termo-adesao-mobile {
  /* border: 1px solid red; */

  color: var(--Secondary-Light-Grey, #727272);
  font-family: "Kumbh Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration-line: underline;
  text-align: left;
  cursor: pointer;
}

.modal-termo-adesao {
  background-color: #fcfcfc;
  border-radius: 14px;
  overflow: hidden;
  height: 90vh;
  width: 90vw;
  padding: 10px 30px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 641px) {
  .right-home {
    /* border: 1px solid red; */
    padding: 2rem;
  }
  /* .header-mobile {
    border: 1px solid red;
  } */
  /* .container-title-description-mobile-version {
    border: 1px solid red;
  }
  .container-termo-adesao-mobile {
    border: 1px solid red;
  } */
}

@media screen and (min-width: 1008px) {
  .modal-termo-adesao {
    display: none;
  }
  .termo-adesao-mobile,
  .h3-custom-mobile,
  .container-title-description-mobile-version,
  .logo-ivi-mobile,
  .container-termo-adesao-mobile {
    transition: all 0.5s ease;
    display: none;
  }
  .right-home {
    /* border: 1px solid blue; */
    width: 50vw;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f8f8f8;
    padding: 2rem;
    transition: padding 0.5s ease;
    overflow-y: hidden;
    /* gap: 2rem; */
  }

  .header-mobile {
    /* border: 1px solid blue; */
    display: none;
  }
  .header-desktop {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

@media screen and (min-width: 1440px) {
  .right-home {
    /* border: 1px solid green; */
    width: 50vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f8f8f8;
    padding: 2rem;
    transition: padding 0.5s ease;
  }

  .termo-adesao-mobile {
    display: none;
  }
}

@media screen and (min-width: 1600px) {
  .right-home {
    padding: 4rem;
  }
}
