.card {
  text-align: center;
  width: 176px;
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px; /* Adiciona uma borda arredondada */
  transition: box-shadow 0.3s ease;
  margin: 10px;
  margin-right: 20px;
}

.card:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}
.card img {
  width: 56px;
  border-radius: 4px;
}

.text-card {
  width: 130px;
  font-size: 12px;
  font-weight: 700;
}
.opacity {
  opacity: 0.5;
}
