/* SecondaryScreen.css */

.my-component {
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: white;
  padding: 1rem;
  overflow-y: auto;
  width: 100%;
  align-items: center;
  min-height: 100vh;
}

.logo-energia-v {
  width: 136px;
  height: 32px;
  z-index: 999;
}

.top-section {
  /* border: 1px solid green; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 33px 35px 35px 32px; */
  transition: padding ease 1s;
  /* margin: 33px 35px 35px 32px; */
  /* padding: 10px;
  margin: 40px; */
  width: 100%;
}

.middle-section {
  /* border: 1px solid purple; */
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .middle-content {
  border: 1px solid blue;
  width: 640px;
  height: 211px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: -33px;
 
} */

.secondary-screen-selo-ivi {
  position: initial;
  padding-bottom: 16px;
  max-width: 440px;
  width: 100%;
}

.bottom-section {
  display: none;
  /* border: 1px solid green;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 40px; */
}

.logo {
  width: 100px;
}

/* .icon {
  Estilize seus ícones conforme necessário
} */

.icon-two {
  margin-left: auto; /* Isso empurra o ícone para a extrema direita */
}
@media screen and (min-width: 641px) {
  .top-section {
    /* border: 1px solid blue; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 32px;
    padding: 16px;
    transition: padding ease 1s;
  }
}
@media screen and (min-width: 1008px) {
  .my-component {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: white;
  }

  .top-section {
    /* border: 1px solid blue; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin: 2rem; */
    transition: padding ease 1s;
    /* margin: 33px 35px 35px 32px; */
    /* padding: 10px;
    margin: 40px; */
  }

  .middle-section {
    /* border: 1px solid green; */
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .middle-content {
    width: 640px;
    height: 211px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: -33px;
    /* Adicione outros estilos necessários */
  }

  .bottom-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin: 40px;
  }

  .logo {
    width: 94px;
  }

  /* .icon {
    Estilize seus ícones conforme necessário
  } */

  .icon-two {
    margin-left: auto; /* Isso empurra o ícone para a extrema direita */
  }

  .secondary-screen-selo-ivi {
    position: absolute;
    bottom: 32px;
    left: 32px;
    margin-bottom: 0px;
  }
}
