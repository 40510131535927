@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;700&display=swap");

.modal-content-component {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  height: 90%;
  /* height: 500px; */
  margin-inline: 11px;
  margin-top: 35px;
  overflow-y: auto;
  transition: all 0.3s;
  overflow-y: auto;
}

.modal-content-component::-webkit-scrollbar {
  width: 7px;
}

.modal-content-component::-webkit-scrollbar-track {
  background: transparent;
}

.modal-content-component::-webkit-scrollbar-thumb {
  border-radius: 3.5px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: rgba(0, 0, 0, 0.5);
}

.modal-content-component::-webkit-scrollbar {
  -webkit-animation: fadein 0.3s;
  animation: fadein 0.3s;
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.content-modal-terms {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow-y: auto;
  max-height: 70vh;
  transition: all 0.5s ease;
  overflow-y: scroll;
  margin-bottom: 1rem;
  padding: 1rem 0.8rem 0 0;
}

.content-modal-terms::-webkit-scrollbar {
  width: 7px;
}

.content-modal-terms::-webkit-scrollbar-track {
  background: transparent;
}

.content-modal-terms::-webkit-scrollbar-thumb {
  border-radius: 3.5px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: rgba(0, 0, 0, 0.5);
}
.content-modal-terms::-webkit-scrollbar {
  -webkit-animation: fadein 0.3s;
  animation: fadein 0.3s;
}

.title-container-modal-terms,
.paragra-container-modal-terms {
  font-family: "Bricolage Grotesque";
  font-size: 16px;
}
.title-container-modal-terms {
  font-weight: 700;
  margin-bottom: 16px;
}
.paragra-container-modal-terms {
  font-weight: 400;
  text-align: justify;
}
.button-modal-container-terms,
.button-modal-container-unapproved-account {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  align-items: center;
  width: 100%;
}

/* Estilos abaixo feitos pelo Atila */
/* Modal.css */
.modal-container-full {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%; /* Ajuste a largura conforme necessário */
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Fundo cinza transparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Certifique-se de que o modal esteja acima do conteúdo existente */
}

.modal-content-full {
  position: relative;
  height: 90vh;
  width: 90%;

  /* width: 750px;
  height: 550px; */
  background-color: #fcfcfc;
  border-radius: 14px;
  overflow: auto;
}

.content-card-boby {
  margin-top: 18px;
  border-radius: 12px;
  background: var(--Primary-contrast-text, #fff);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
}

/* .content-card-boby div {
  padding-inline: 16px;
} */

.modal-content-component [data-open] span:nth-child(1) {
  font-weight: bold;
  transition: all 0.3s;
}

.animation-body button > span[data-open] {
  --tw-rotate: -180deg;
}

.content-card-boby {
  font-size: 16px;
}

.text-modal-full {
  font-weight: bold;
}

.centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center; /* Centraliza o conteúdo horizontalmente */
  height: 100%; /* Garante que a altura ocupe 100% do contêiner da modal */
}

@media screen and (min-height: 843px) {
  .modal-content-component {
    /* border: 1px solid blue; */
    width: 95%;
  }

  .modal-content-full {
    height: 45vh;
  }
}

@media screen and (min-width: 1008px) {
  .modal-content-full {
    position: relative;
    width: 750px;
    height: 550px;
    background-color: #fcfcfc;
    border-radius: 14px;
    overflow: hidden;
  }
  .content-modal-terms {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    overflow-y: auto;
    max-height: 50vh;
    transition: max-height 0.5s ease;
    overflow-y: scroll;
    margin-bottom: 1rem;
    padding: 1rem 0.8rem 0 0;
  }
  .modal-content-component {
    display: flex;
    flex-direction: column;
    height: 500px;
    margin-inline: 48px;
    margin-top: 48px;
    overflow-y: auto;
    transition: all 0.3s;
  }
  .content-card-boby div {
    padding-inline: 16px;
  }
}
