@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;700&display=swap");

.container-contact {
  /* border: 1px solid blue; */
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  /* padding: 2rem 0 5rem 0; */
}

.dados-title-titular {
  font-size: 16px;
  font-family: "Bricolage Grotesque";
  font-weight: bold;
  line-height: 28px;
}
#modal-erros {
  /* width: auto; */
  /* border: 1px solid red; */
  border-radius: 8px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 4rem 0;
  border-radius: 14px;
  width: 95%;
}
.modal-error-header {
  text-align: center;
  font-family: "Bricolage Grotesque";
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 4px; */
  padding-bottom: 24px;
}
.email-exist-text {
  width: 85%;
}

.modal-error-title {
  font-weight: bold;
  line-height: 24px;
}

.btn-email-exists {
  height: 48px;
  padding-inline: 24px;
  border: none;
  border-radius: 12px;
  outline: none;
  background: var(--main-color);
  box-shadow: 0px 10px 40px -12px #ffffff52;
  white-space: nowrap;
  letter-spacing: 1px;
  color: var(--continue-button-font);
  font-weight: 700;
  font-size: 14px;
  font-family: "Kumbh Sans";
  text-align: center;
  cursor: pointer;
}
.btn-cpf-email-exist-alterar {
  width: 230px;
  height: 48px;
}
.btn-cpf-email-exist-voltar {
  width: 230px;
  height: 48px;
}

.btn-voltar-exist {
  height: 48px;
  padding-inline: 24px;
  width: 230px;
  border: 1px solid var(--back-button-color);
  border-radius: 12px;
  box-shadow: 0px 10px 40px -12px #ffffff52;
  outline: none;
  background: #fcfcfc;
  color: var(--back-button-color);
  letter-spacing: 1px;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  font-family: "Kumbh Sans";
  cursor: pointer;
}

.container-button-email-exists {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  gap: 1rem;
  width: 100%;
}

.btn-modal-titular {
  margin: 24px 0px 0px 0px;
  height: 48px;
  padding: 0px 24px;
  border-radius: 12px;
  outline: none;
  font-weight: 800;
  letter-spacing: 1px;
  color: #2b134b;
  background: var(--main-color);
  cursor: pointer;
  box-shadow: 0px 10px 40px -12px #ffffff52;
  text-align: center;
  border: none;
}

.termos-de-uso-link {
  color: #777777;
  text-decoration: underline;
  cursor: pointer;
}

.header-modal p {
  line-height: 24px;
  font-size: 15px;
}

.error-mgs-contact {
  /* border: 1px solid blue; */
  font-family: "Kumbh Sans";
  font-size: 12px;
  font-weight: 400;
  color: red;
  width: 260px;
  /* margin-top: 2px; */
}
.container-contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 18rem;
  height: 18rem;
}
.container-inputs-contact {
  width: 280px;
  background: #fff;
  border-radius: 14px;
  height: 56px;
  position: relative;
  margin: 0.3rem 0;
}

.checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: start;
  width: 280px;
  font-size: 12px;
  gap: 5px;
  
}
.checkbox-container input {
    margin: 3px 0;
}

.container-button-contact {
  width: 253px;
  display: flex;
  justify-content: space-evenly;
  margin: 0.5rem 0;
}

.modal-content-container__title {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  font-family: "Roboto", sans-serif;
}

.modal-content-container__subtitle {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  font-family: "Roboto", sans-serif;
}

/* ------------------- */

.btn-voltar-contact {
  width: 89px;
  height: 48px;

  border: 1px solid #000;
  border-radius: 12px;
  box-shadow: 0px 10px 40px -12px #ffffff52;
  outline: none;
  background: #fcfcfc;

  letter-spacing: 1px;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  font-family: "Kumbh Sans";

  cursor: pointer;
}

.btn-continue-contact {
  height: 48px;
  width: 114px;

  border: none;
  border-radius: 12px;
  outline: none;
  background: var(--main-color);
  box-shadow: 0px 10px 40px -12px #ffffff52;

  letter-spacing: 1px;
  color: #2b134b;
  font-weight: 700;
  font-size: 14px;
  font-family: "Kumbh Sans";
  text-align: center;
  cursor: pointer;
}
@media screen and (min-width: 553px) {
  .container-button-email-exists {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
    gap: 1rem;
    width: 100%;
  }
  .btn-voltar-exist {
    height: 48px;
    width: 12rem;
    padding: 0;
  }
  .btn-cpf-email-exist-alterar {
    width: 127px;
    height: 48px;
  }
  .btn-cpf-email-exist-voltar {
    width: 142px;
    height: 48px;
  }
}

@media screen and (min-width: 641px) {
  .container-contact {
    /* border: 1px solid blue; */
    justify-content: flex-start;
    padding: 2rem 0 5rem 0;
    gap: 2rem;
  }
  .container-contact-form {
    /* border: 1px solid blue; */
    height: 21rem;
  }
  .btn-email-exists {
    height: 48px;

    width: 249px;
    padding: 0 2rem;
  }
  .btn-cpf-email-exist-alterar {
    width: 230;
  }

  .btn-voltar-exist {
    height: 48px;
    width: 12rem;
    padding: 0;
  }
  #modal-erros {
    width: 70%;
  }
  .container-button-email-exists {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
    gap: 1rem;
    width: 100%;
  }
}
@media screen and (min-width: 1008px) {
  .container-contact {
    /* border: 1px solid green; */
    justify-content: center;
    /* padding: 2rem 0 5rem 0; */
    /* gap: 2rem; */
  }
  .container-contact-form {
    /* border: 1px solid green; */
    max-height: 20rem;
    justify-content: center;
    /* gap: 0.3rem; */
  }
  .container-inputs-contact {
    margin: 0.3rem 0;
  }
  .container-button-contact {
    /* border: 1px solid green; */
    margin: 0.5rem 0;
  }
  #modal-erros {
    width: 90%;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    padding: 48px;
    border-radius: 14px;
  }
  .modal-error-header {
    text-align: center;
    font-family: "Bricolage Grotesque";
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    padding-bottom: 24px;
  }
  .btn-cpf-email-exist-alterar {
    width: 8rem;
  }
  .container-button-email-exists {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: row;
    padding: 0;
    justify-content: center;
    gap: 16px;
    width: 100%;
  }
}

/* nextui-custom-theme.css */
.teste {
  width: 280px;
  background: var(--Primary-White, #fff);
  border-radius: 14px;
  height: 56px;
  position: relative;
}

.cnpjCpf {
  width: 280px;
  margin-bottom: 16px;
  border-radius: 14px;
  background: hsla(0, 0%, 93%, 1);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0);
}

h3 {
  color: var(--Primary-Black, #000);
  font-family: "Bricolage Grotesque";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
p {
  color: var(--Primary-Black, #000);
  font-family: "Bricolage Grotesque";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btn-contact {
  height: 48px;
  padding: 0px 24px;
  margin: 4px;
  border-radius: 12px;
  outline: none;
  font-weight: 800;
  letter-spacing: 1px;
  background: var(--main-color);
  color: var(--continue-button-font);
  cursor: pointer;
  box-shadow: 0px 10px 40px -12px #ffffff52;
  text-align: center;
  border: none; /* Remova esta linha para retirar a borda */
}
.btn-voltar {
  height: 48px;
  padding: 0px 24px;
  margin: 4px; /* Ajuste a margem conforme necessário */
  border: 2px solid #000; /* Alterado para adicionar uma borda sólida */
  border-radius: 12px;
  outline: none;
  font-weight: 800;
  letter-spacing: 1px;
  background: #fcfcfc;
  cursor: pointer;
  box-shadow: 0px 10px 40px -12px #ffffff52;
  text-align: center;
}

.a-home {
  width: 100%; /* Usar a largura completa da div .card-home */
  padding: 10px 0px;
  margin-top: 16x; /* Adicione a margem desejada acima do link */
  text-align: center;
  color: #414141;
  text-decoration: underline;
  text-underline-offset: 2px;
}
.card-home > h3 {
  color: #000000;
}

.card-home > h5 {
  color: #a2a2a2;
}
.button-container {
  width: 253px;
  margin-right: 15px;
  margin-top: 3px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center; /* Centraliza horizontalmente */
  cursor: pointer;
}
