#modal-erro {
  width: auto;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  padding: 48px;
  border-radius: 14px;
}

.modal-error-text {
  width: 271px;
  line-height: 24px;
}