@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:wght@400;700&display=swap");

.middle-content-registration-complet {
  /* border: 1px solid red; */
  width: 100%;
  /* max-width: 704px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-grow: 1;
  padding: 0 20px
}

.title-registration-complet {
  /* border: 1px solid blue; */
  font-size: 24px;
  font-weight: 700;
  font-family: "Bricolage Grotesque";
  margin: 1rem 0;
}

.description-registration-complet {
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* height: 133px; */
}

.description-registration-complet > p {
  font-family: "Bricolage Grotesque";
}

.arrow {
  margin: 20px;
  transform: rotate(0.25turn);
}

.thunder-icon {
  width: 60px;
  margin-top: 20px;
}

.registration-completed-status {
  display: flex;
  max-width: 520px;
  width: 100%;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 28px;
}

.registration-completed-status-card > img {
  width: 60px;
}

.registration-completed-status-card > p {
  max-width: 18ch;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 600;
}

.registration-completed-status-card[data-active="false"] > p {
  opacity: 0.25;
}

.registration-completed-status-card[data-active="true"] > img {
  margin-right: -16px;
}

.registration-completed-status-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.registration-completed-status-selo-ivi {
  margin-top: 20px;
}

.registration-completed-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.registration-completed-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
  position: absolute;
  width: 100%;
}


@media screen and (min-width: 1008px) {
  .registration-completed-header {
    padding: 16px 32px;
    position: absolute;
    width: 100%;
  }
  .middle-content-registration-complet {
    /* border: 1px solid red; */
    width: 80%;
    /* max-width: 704px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    height: 100%;
  }

  .title-registration-complet {
    font-size: 24px;
    font-weight: 700;
    font-family: "Bricolage Grotesque";
  }

  .description-registration-complet {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 133px;
    max-width: 576px;
  }

  .arrow {
    transform: none;
  }

  .registration-completed-status-card > img {
    width: 76px;
  }

  .registration-completed-status {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  
  .thunder-icon {
    width: 80px;
  }
}
