@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;700&display=swap");

.container-installation {
  /* border: 1px solid red; */
  /* width: 60%;
  height: 75%; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* height: 63%; */
  align-items: center;

  transition: padding 0.5s ease;
}

.text-inner-card {
  font-weight: 400;
  font-family: "Kumbh Sans";
  font-size: 16px;
  line-height: 150%;
}

.asterisco {
  /* border: 1px solid blue; */
  /* margin-bottom: 1rem; */
  width: 14.5rem;
  color: #727272;
  font-weight: 400;
  font-family: "Kumbh Sans";
}

.pdf-error-text {
  width: 100%;
  line-height: 24px;
}

.error-error-file {
  margin: 0.25rem 0;
  color: red;
  font-size: 12px;
  font-family: "Bricolage Grotesque";
  width: 290px;
  text-align: center;
}

.error-empty-file {
  margin: 0.5rem 0;
  color: red;
  font-size: 12px;
  font-family: "Bricolage Grotesque";
  width: 290px;
  text-align: center;
}
.container-input-password {
  /* border: 1px solid blue; */
  width: 280px;
  margin: 0.5rem 0 1rem 0;
  background: var(--Primary-White, #fff);
  border-radius: 14px;
  height: 56px;
}

.button-container-installation {
  /* border: 1px solid blue; */
  width: 220px;
  display: flex;
  justify-content: space-between;
}
.container-button-modal-erro {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 0 0.3rem; */
  width: 80%;
  gap: 1rem;
}
.modal-erros-leitura {
  /* width: auto; */
  /* border: 1px solid blue; */
  position: relative;
  width: 95%;
  height: 70%;
  background-color: #fff;
  display: flex;
  gap: 2rem;
  justify-content: center;
  flex-direction: column;
  /* padding: 2rem 0; */
  border-radius: 14px;
  align-items: center;
}
.modal-children-erro {
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
}
.modal-error-header-leitura {
  /* border: 1px solid red; */
  width: 100%;
  text-align: center;
  font-family: "Bricolage Grotesque";
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0.5rem;
}
.button-back-modal-error-leitura,
.button-continue-modal-error-leitura {
  width: 82%;
}

@media screen and (min-width: 641px) {
  .container-installation {
    /* border: 1px solid blue; */
    height: 70%;
    transition: height 0.5s ease;
  }
  .modal-erros-leitura {
    /* width: auto; */
    /* border: 1px solid blue; */

    width: 75%;
    height: 70%;
  }
  .container-button-modal-erro {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: row;
    /* padding: 0 0.3rem; */
    width: 100%;
    gap: 1rem;
  }
  .button-continue-modal-error-leitura {
    width: 46%;
  }
  .button-back-modal-error-leitura {
    width: 38%;
  }
}

@media screen and (min-width: 1008px) {
  .container-installation {
    /* border: 1px solid red; */
    width: 100%;
    height: 100%;
    /* padding: 5rem; */
    justify-content: center;
    transition: padding 0.5s ease;
  }
  .container-button-modal-erro {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: row;
    /* justify-content: space-evenly; */
    align-items: center;
    padding: 0;
    width: 100%;
    gap: 1rem;
  }

  .modal-erros-leitura {
    /* width: auto; */
    /* border: 1px solid blue; */
    height: 316px;
    width: 515px;
    /* max-width: 90vw; */
    padding: 0;
  }
  .button-continue-modal-error-leitura {
    width: 220px;
  }
  .button-back-modal-error-leitura {
    width: 183px;
  }
  .modal-error-header-leitura {
    /* border: 1px solid red; */

    gap: 0.5rem;
    padding: 0;
  }
  .pdf-error-text {
    /* border: 1px solid red; */
    width: 395px;
    line-height: 24px;
  }
}
