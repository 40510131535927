@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;700&display=swap");

.container-create-holder-cpf {
  /* border: 1px solid blue; */
  width: 100%;
  height: 100%;
  /* height: 100vh; */
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  /* overflow-y: scroll; */
  /* justify-content: space-between; */
  align-items: center;
  /* padding: 1rem 0 2rem; */
}
.container-form-button-cpf {
  /* border: 1px solid blue; */
  display: flex;
  align-items: center;
  flex-direction: column;
  /* gap: 1rem; */
  justify-content: space-between;
  height: 100%;
  overflow-y: auto;
}
.container-form-cpf {
  /* border: 1px solid green; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  transition: all 0.5s ease;
  overflow-y: auto;
  /* padding: 0.3rem 0; */
}

.container-inputs-next-ui {
  width: 280px;
  background: var(--Primary-White, #fff);
  border-radius: 14px;
  height: 56px;
  position: relative;
  margin: 0.3rem 0;
}

.container-cpf-create {
  /* border: 1px solid blue; */
  width: 280px;
  border-radius: 14px;
  background: hsla(0, 0%, 93%, 1);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0);
}

.error-mgs-create-cpf {
  /* border: 1px solid blue; */
  font-family: "Kumbh Sans";
  font-size: 11px;
  font-weight: 400;
  color: red;
  width: 260px;
}

@media screen and (min-height: 641px) {
  .container-form-cpf {
    /* border: 1px solid red; */
    height: 80%;
  }
  .container-form-button-cpf {
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    height: 80%;
  }
}
@media screen and (min-height: 820px) {
  .container-form-cpf {
    /*  */
    padding: 0;
    height: 70%;
  }
  .container-form-button-cpf {
    /* border: 1px solid green; */
    display: flex;
    gap: 0;
    height: 60%;
  }
}
@media screen and (min-width: 1008px) {
  .container-create-holder-cpf {
    justify-content: center;
  }
  .container-form-cpf {
    /* border: 1px solid red; */
    height: 90%;
    overflow-y: hidden;
  }
  .container-form-button-cpf {
    overflow-y: hidden;
  }
}
