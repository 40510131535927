@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;700&display=swap");

.section-datas-razao-social {
  /* border: 1px solid red; */
  width: 100%;
  display: grid;
  grid-template-areas:
    "cnpj-div"
    "razao-social-div";
  gap: 1rem;
}

#cnpj-div {
  grid-area: cnpj-div;
}

#razao-social-div {
  grid-area: razao-social-div;
}

.label-cnpj-social {
  color: #727272;
  font-family: "Kumbh Sans";
  font-size: 14px;
}
.value-cnpj-social {
  font-family: "Kumbh Sans";
  font-size: 16px;
}

@media screen and (min-width: 641px) {
  .section-datas-razao-social {
    /* border: 1px solid red; */
    width: 100%;
    display: grid;
    grid-template-areas: "cnpj-div cnpj-div cnpj-div razao-social-div";
    gap: 2rem;
  }
}
