.calculator-content {
  background-color: white;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {

  /* Ajuste este valor conforme necessário para definir o breakpoint móvel */
  .calculator-content {
    padding-inline: 0;
    flex-direction: column;
    align-items: center;
    /* Altere a direção do conteúdo para coluna em dispositivos móveis */
    height: auto;
    /* Ajuste a altura conforme necessário para o layout em coluna */
  }
}


.calculator {
  background-color: white;
  border: 1px solid #DDDDDD;
  width: 410px;
  padding-bottom: 16px;
  border-radius: 20px;
}

@media (max-width: 768px) {

  /* Ajuste este valor conforme necessário para definir o breakpoint móvel */
  .calculator {

    align-self: center;
    margin-right: 0;
    /* Ajuste a altura conforme necessário para o layout em coluna */
  }
}

.meet-advantages-title {
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;

  color: #660099;
  margin-bottom: 32px;
}


.title-content {
  background-color: #EBE8ED;
  width: 410px;
  height: 54px;
  color: black;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new {
  width: 566px;
  height: 296px;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  flex-direction: column;
}

/* components/VolumeSlider.module.scss */
.sliderContainer {
  text-align: center;
  margin-top: 20px;
}

.volumeLabel {
  margin-bottom: 20px;
  font-size: 24px;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 80%;
  height: 8px;
  background: #ddd;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  transform: translateY(-50%);
  position: relative;
}

.slider::-moz-range-thumb {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  transform: translateY(-50%);
  position: relative;
}

.slider::-ms-thumb {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  transform: translateY(-50%);
  position: relative;
}

.slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: linear-gradient(to right,
      #660099 0%,
      #660099 calc((var(--slider-value) - 300) / 19700 * 100%),
      #ddd calc((var(--slider-value) - 300) / 19700 * 100%),
      #ddd 100%);
}

.slider::-moz-range-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: linear-gradient(to right,
      #660099 0%,
      #660099 calc((var(--slider-value) - 300) / 19700 * 100%),
      #ddd calc((var(--slider-value) - 300) / 19700 * 100%),
      #ddd 100%);
}

.slider::-ms-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: linear-gradient(to right,
      #660099 0%,
      #660099 calc((var(--slider-value) - 300) / 19700 * 100%),
      #ddd calc((var(--slider-value) - 300) / 19700 * 100%),
      #ddd 100%);
  border-color: transparent;
  color: transparent;
}

.economyBox {
  width: 376px;
  height: 80px;
  background-color: #F6F6F6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px auto 0;
  text-align: center;
  /* Centraliza o texto dentro do contêiner */
}

.economyText {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 4px;
  /* Espaço entre o texto e o valor */
}

.economyValue {
  font-size: 24px;
  font-weight: 700;
}




.saveButton {
  width: 280px;
  height: 48px;
  background-color: #660099;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin: 20px auto 0;
  font-size: 16px;
  cursor: pointer;
}




.headingTitle {
  font-size: 21px;
  font-weight: 700;
  text-align: center;
  /* Opcional: centraliza o texto se necessário */
}



