.seloContainer {
  display: flex;
  align-items: center;

  border-radius: 8px;
  border: solid 1px rgba(0, 0, 0, 0.6);
  padding: 12px;

  max-width: 442px;
  width: 100%;
}

.seloLogo {
  width: 96px;
}

.seloDescription {
  font-size: 12px;
  display: flex;
  align-items: center;
}

.seloDescription::before {
  content: "";
  margin-left: 12px;
  margin-right: 12px;
  display: block;
  width: 1px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.6);
}

@media screen and (max-width: 641px) {
  .seloDescription {
    font-size: 8px;
  }

  .seloContainer {
    max-width: 300px;
    align-self: center;
  }
}