@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;700&display=swap");

.container-holder {
  /* border: 1px solid red; */
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0 1rem 0;
  /* gap: 4rem; */
}

.holder-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container-input-holder {
  /* border: 1px solid red; */
  width: 280px;
  margin: 0.5rem 0;
  background: var(--Primary-White, #fff);
  border-radius: 14px;
  height: 56px;
  position: relative;
}
.container-input-button-holder {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;

  /* gap: 0.5rem; */
}
.error-mgs-holder {
  font-family: "Kumbh Sans";
  font-size: 12px;
  font-weight: 400;
  color: red;
  width: 260px;
  text-align: center;
}

.icon-information {
  /* border: 1px solid red; */
  margin: 0.5rem 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  gap: 8px;
  /* margin-block: 16px; */
  align-items: center;
}

.icon-information p {
  font-family: "Kumbh Sans";
}

h3 {
  color: var(--Primary-Black, #000);
  font-family: "Bricolage Grotesque";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
p {
  color: #000;
  font-family: "Bricolage Grotesque";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.button-container-holder {
  /* border: 1px solid red; */
  width: 220px;
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
}
.modal-content-titular {
  padding: 1rem;
}

@media screen and (min-width: 641px) {
  .container-holder {
    /* border: 1px solid blue; */
    height: 100%;
    justify-content: flex-start;
  }
}
@media screen and (min-width: 1008px) {
  .container-holder {
    /* border: 1px solid green; */
    height: 100%;
    justify-content: center;
    padding: 0;
  }
}

.btn-contact {
  border: 1px solid red;
  height: 48px;
  padding: 0px 24px;
  margin: 4px;
  border-radius: 12px;
  outline: none;
  font-weight: 800;
  letter-spacing: 1px;
  color: var(--continue-button-font);
  background: var(--main-color);
  cursor: pointer;
  box-shadow: 0px 10px 40px -12px #ffffff52;
  text-align: center;
  border: none; /* Remova esta linha para retirar a borda */
  cursor: pointer;
  transition: all 400ms ease;

  &:not(&:disabled) {
    &:hover {
      transform: translateY(-3px);
    }
  }
}
.btn-voltar {
  height: 48px;
  /* padding: 0px 24px; */
  margin: 4px; /* Ajuste a margem conforme necessário */
  border: 1px solid #000; /* Alterado para adicionar uma borda sólida */
  border-radius: 12px;
  outline: none;
  font-weight: 800;
  letter-spacing: 1px;
  background: #fcfcfc;
  cursor: pointer;
  box-shadow: 0px 10px 40px -12px #ffffff52;
  text-align: center;
  cursor: pointer;
  transition: all 400ms ease;

  &:not(&:disabled) {
    &:hover {
      transform: translateY(-3px);
    }
  }
}
/* 
.a-home {
  width: 100%; 
  padding: 10px 0px;
  margin-top: 16px; 
  text-align: center;
  color: #414141;
  text-decoration: underline;
  text-underline-offset: 2px;
} */
/* .card-home > h3 {
  color: #000000;
} */

/* .card-home > h5 {
  color: #a2a2a2;
} */
