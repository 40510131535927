@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;700&display=swap");

.section-datas-installation {
  /* border: 1px solid blue; */
  width: 100%;
  gap: 1rem;
  display: grid;
  grid-template-areas:
    "distributor-instalation-div number-instalation-div number-instalation-div"
    "address-instalation-div address-instalation-div address-instalation-div";
}

#distributor-instalation-div {
  grid-area: distributor-instalation-div;
}
#number-instalation-div {
  grid-area: number-instalation-div;
}
#address-instalation-div {
  grid-area: address-instalation-div;
}

.label-data-installation {
  /* border: 1px solid blue; */
  color: #727272;
  font-family: "Kumbh Sans";
  font-size: 14px;
  align-items: flex-start;
  /* white-space: nowrap; */
}

.value-installation {
  font-family: "Kumbh Sans";
  font-size: 16px;
  /* align-items: flex-start;
  justify-content: flex-start; */
}

.container-label-value-installation {
  /* border: 1px solid red; */
  max-width: 80%;
  /* max-width: 260px; */
}
/* .container-label-value-installation-address {
  border: 1px solid green;
  max-width: 60%;
} */

/* .text-smash-end {
  border: 1px solid red;
  max-width: 245px;
  overflow-wrap: break-word;
} */

@media screen and (min-width: 560px) {
  .section-datas-installation {
    grid-template-areas: "distributor-instalation-div number-instalation-div address-instalation-div";
    gap: 1rem;
  }
  /* .label-data-installation,
  .value-installation {
    font-size: 16px;
  } */
}
