.newModalContainer {
  position: relative;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 4rem 2rem;
  background-color: #fff;
  border-radius: 14px;
  width: 95%;

  transform: scale(0.8);
  animation-name: modalEnterAnimation;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

@keyframes modalEnterAnimation {
  100% {
    opacity: initial;
    transform: initial;
  }
}

.newModalContent {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.newModalHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.newModalBackground {
  /* border: 1px solid red; */
  position: fixed;
  top: 0;
  right: 0;
  width: 100%; /* Ajuste a largura conforme necessário */
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Fundo cinza transparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Certifique-se de que o modal esteja acima do conteúdo existente */
  opacity: 0.5;
  animation: fadeIn 0.3s forwards;
}

@keyframes fadeIn {
  100% {
    opacity: 1;
  }
}

.newModalPosition {
  width: 100%;
  height: 100%;
  position: absolute;
  background: transparent;
  left: 0;
}

.newModalTitle {
  font-weight: bold;
  line-height: 24px;
  text-align: center;
  font-family: "Bricolage Grotesque", sans-serif;
}

.newModalBody > * {
  line-height: 24px;
  text-align: center;
  margin-bottom: 20px;
}

.newModalFooter {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.closeIcon {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  font-size: 20px;
  color: #000;
}

@media screen and (min-width: 1008px) {
  .newModalBackground {
    /* border: 1px solid blue; */
    position: fixed;
    top: 0;
    right: 0;
    width: 50%; /* Ajuste a largura conforme necessário */
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Fundo cinza transparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Certifique-se de que o modal esteja acima do conteúdo existente */
  }

  .newModalContainer {
    position: relative;
    width: 95%;
    height: fit-content;
    padding: 20px;
    border-radius: 8px;
  }

  .newModalContainer {
    width: 90%;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    padding: 48px;
    border-radius: 14px;
  }
}

@media screen and (min-width: 641px) {
  .newModalContainer {
    width: 80%;
  }
}
