@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:wght@400;700&display=swap");
.container-page-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title-page-not-found {
  font-size: 24px;
  font-weight: 700;
  font-family: "Bricolage Grotesque";
}
.description-page-not-found {
  font-size: 14px;
  font-weight: 400;
  font-family: "Bricolage Grotesque";
  text-align: center;
}
@media screen and (min-width: 641px) {
  .description-page-not-found {
    font-size: 16px;
  }
}
