@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;700&display=swap");
body {
  font-family: "Kumbh Sans";
}
.custom-card {
  width: 640px;
  background-color: #ffffff;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  border-radius: 14px;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.title {
  font-weight: bold;
  color: black;
  font-size: 18px;
}

.edit-icon {
  cursor: pointer;
  margin-right: -6px;
}

/* .data-container {
  display: flex;
  gap: 16px;
} */

.data {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.label-custom-card {
  color: #727272;
  font-family: "Kumbh Sans";
  font-size: 14px;
  align-items: flex-start;
  white-space: nowrap;
}

.value {
  font-family: "Kumbh Sans";
  font-size: 14px;
  align-items: flex-start;
  justify-content: flex-start;
}

.smash {
  font-family: "Kumbh Sans";
  font-size: 14px;
  white-space: nowrap;
}
