@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;700&display=swap");

.container-home {
  /* border: 1px solid red; */
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0 2rem 0;
}
.container-select-home {
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60%;
}
.select-inputs {
  background-color: #ffffff;
  border-radius: 14px !important;
  width: 280px !important ;
  height: 56px !important ;
  margin-bottom: 0px !important ;
}

.modal-children-cadastro {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* width: 20rem; */
  height: 300px;
  padding: 1rem;
}

.p-cadastro-continue {
  width: 100%;
  padding: 10px 0px;
  /* margin-top: 1rem; */
  text-align: center;
  color: #414141;
  text-decoration: underline;
  text-underline-offset: 2px;
  cursor: pointer;
}

/* Estilos abaixo feito pelo atila */

/* body {
  overflow: hidden;
  margin: 0;
  font-family: "Kumbh Sans", sans-serif;
} */

/* #input {
  border: 1px solid red;
  font-size: 16px;
} */

/* .coment-lado-direito {
  position: fixed;
  bottom: 40px;
  right: 40px;
  color: var(--Sua-Cor, #000);
  font-family: "Sua-Fonte";
  font-size: 16px;
} */

p {
  color: var(--Primary-Black, #000);
  font-family: "Bricolage Grotesque";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btn-homee {
  height: 48px;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  margin: 20px auto; /* Adição de 'auto' para centralizar horizontalmente */
  border: none;
  border-radius: 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  color: #212121;
  background: var(--main-color);
  cursor: pointer;
  box-shadow: 0px 10px 40px -12px #ffffff52;
  text-align: center;
  cursor: pointer;
  transition: all 400ms ease;

  &:not(&:disabled) {
    &:hover {
      transform: translateY(-3px);
    }
  }
}

/* .a-home {
  width: 100%;
  padding: 10px 0px;
  margin-top: 16x;
  text-align: center;
  color: #414141;
  text-decoration: underline;
  text-underline-offset: 2px;
} */

.select-home {
  border: 1px solid red;
  background-color: #ffffff !important;
  border-radius: 10px !important;
  width: 254px !important;
  height: 56px !important;
}

.select-home-two {
  background-color: #ffffff !important;
  border-radius: 10px !important;
  width: 280px !important;
  height: 56px !important;
  margin-bottom: 16px !important;
}

.select-home-two,
.select-home {
  button {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1) !important;

    & > label {
      font-size: 14px;
    }
  }

  span {
    color: #000000;
  }
}

ul {
  background-color: #fff;
}

@media only screen and (min-width: 641px) {
  .container-home {
    /* border: 1px solid blue; */
    /* height: 60vh; */
    justify-content: space-around;
  }

  /* .container-select-home {
    border: 1px solid blue;
  } */
}

@media screen and (min-width: 1008px) {
  .container-home {
    /* border: 1px solid red; */
    height: 100%;
    padding: 0;
  }
  .container-select-home {
    /* border: 1px solid red; */
    justify-content: center;
  }
  .modal-children-cadastro {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* width: 376px;
    height: 300px; */
    padding: 1rem;
  }
}
