@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;700&display=swap");
.section-container-card-information {
  /* border: 1px solid red; */
  width: 100%;
  border-radius: 14px;
  background: var(--main-color);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease;
}
.card-information {
  /* border: 1px solid blue; */
  color: var(--continue-button-font);
  width: 100%;
  padding: 8px 24px;
}

.card-information * {
  color: var(--continue-button-font);
}
.container-asterisco {
  background: var(--secondary-color);
  width: 100%;
  padding: 8px 24px;
  border-radius: 0 0 14px 14px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
}

.container-asterisco * {
  color: var(--continue-button-font);
}

.data-container {
  width: 100%;
  /* border: 1px solid blue; */
  gap: 0.6rem;
  display: grid;
  /* align-items: center; */
  /* justify-content: space-between; */
  grid-template-areas:
    "discount-div economy-div economy-div"
    "consumo-div  consumo-div consumo-div";
}
#discount-div {
  grid-area: discount-div;
  /* border: 1px solid red; */

  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: space-between; */

  /* align-items: baseline; */
  /* width: 100%; */
}
#consumo-div {
  grid-area: consumo-div;
  /* width: 12.2rem; */
  /* border: 1px solid rgb(55, 0, 255); */
}
#economy-div {
  grid-area: economy-div;
  /* border: 1px solid rgb(243, 10, 255); */
}
.asterisco-info-card-info {
  /* margin: 0.3rem auto 0 auto; */
  font-family: "Kumbh Sans";
  font-size: 12px;
  text-align: justify;
}
.value-bold,
.discount,
.porcentagem {
  font-family: "Kumbh Sans";
  font-weight: bold;
  font-size: 16px;
  margin-top: 0.5rem;
}

.title {
  font-weight: bold;
  font-size: 16px;
}

/* .discount {
  font-weight: bold;
  font-family: "Kumbh Sans";
  font-size: 40px;
}

.porcentagem {
  font-size: 24px;
} */

.label {
  font-family: "Kumbh Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

@media screen and (min-width: 500px) {
  .data-container {
    /* border: 1px solid red; */
    grid-template-areas: "discount-div economy-div consumo-div consumo-div ";
    /* width: 25rem; */
    gap: 1.5rem;
    /* width: 391px; */
  }
  #discount-div {
    grid-area: discount-div;
    /* border: 1px solid rgb(21, 0, 255); */
  }
  #discount-div,
  #economy-div,
  #consumo-div {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: space-between; */
  }
  .discount,
  .porcentagem,
  .value-bold {
    font-size: 20px;
  }
  #consumo-div {
    grid-area: consumo-div;
    /* width: 12.2rem; */
    /* border: 1px solid rgb(55, 0, 255); */
  }
}

@media screen and (min-width: 641px) {
  .section-container-card-information {
    width: 95%;
  }
  .card-information {
    width: 100%;
  }
  .label {
    padding-right: 1rem;
    /* word-break: break-all; */
  }
}

/* @media screen and (min-width: 744px) {
  .data-container {
    gap: 0.1rem;
    justify-content: space-around;
  }
  #discount-div {
    border: 1px solid red;
    width: 100%;
    margin: auto 2rem;
  }
} */
/* @media screen and (min-width: 797px) {
  .data-container {
    gap: 1rem;
    justify-content: space-between;
  }
} */

/* @media screen and (min-width: 885px) {
  .data-container {
    gap: 1.8rem;
    justify-content: start;
  }
} */

@media screen and (min-width: 1008px) {
  .section-container-card-information {
    width: 100%;
  }
  .card-information {
    width: 100%;
  }

  .label {
    font-size: 14px;
  }
  .data-container {
    gap: 0.3rem;
  }
  .discount,
  .porcentagem,
  .value-bold {
    font-size: 16px;
  }

  /* .discount {
    font-size: 40px;
  } */
}
@media screen and (min-width: 1100px) {
  .discount,
  .porcentagem,
  .value-bold {
    font-size: 20px;
  }
  .label {
    padding-right: 0;
  }
  /* .discount {
    font-size: 40px;
  } */
}

/* @media screen and (min-width: 1552px) {
  .data-container {
    padding-right: 10rem;
  }
}
@media screen and (min-width: 1720px) {
  .data-container {
    padding-right: 13rem;
  }
} */

/* @media screen and (min-width: 1230px) and (min-height: 780px) {
  .card-information {
    border: 1px solid red;
    max-height: 18%;
  }
} */
