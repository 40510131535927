.card-comfirmData {
  /* border: 1px solid red; */
  /* height: 60vh; */
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  align-items: center;
  /* margin: 0.5rem 0; */
}
.card-container-confirm {
  /* border: 1px solid purple; */

  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  overflow-y: scroll;
  gap: 0.5rem;
  margin-bottom: 1rem;
  transition: height 0.5s ease;
}

.card-container-confirm::-webkit-scrollbar {
  width: 7px;
}

.card-container-confirm::-webkit-scrollbar-track {
  background: transparent;
}

.card-container-confirm::-webkit-scrollbar-thumb {
  border-radius: 3.5px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: rgba(0, 0, 0, 0.5);
}

.button-container-confirm-data {
  /* border: 1px solid red; */
  width: 220px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
@media screen and (min-width: 475px) {
  .card-comfirmData {
    /* border: 1px solid red; */
    /* height: 72vh; */
    width: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */
    align-items: center;
    margin: 0.5rem 0;
  }
  .card-container-confirm {
    /* border: 1px solid purple; */

    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    overflow-y: scroll;
    gap: 0.5rem;
    margin-bottom: 1rem;
    transition: all 0.5s ease;
  }

  .card-container-confirm::-webkit-scrollbar {
    width: 7px;
  }

  .card-container-confirm::-webkit-scrollbar-track {
    background: transparent;
  }

  .card-container-confirm::-webkit-scrollbar-thumb {
    border-radius: 3.5px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    background: rgba(0, 0, 0, 0.5);
  }

  .button-container-confirm-data {
    /* border: 1px solid red; */
    width: 220px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
}

@media screen and (min-width: 641px) {
  .card-container-confirm {
    align-items: center;
  }
}

@media screen and (min-width: 1008px) {
  .card-comfirmData {
    /* border: 1px solid red; */
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */
    align-items: center;
  }
  .card-container-confirm {
    /* border: 1px solid green; */
    /* width: 35rem; */
    width: 100%;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    overflow-y: scroll;

    gap: 0.5rem;
    transition: all 0.5s ease;
    margin-top: 0;
  }
}

@media screen and (min-width: 1500px) and (min-height: 800px) {
  .card-comfirmData {
    /* border: 1px solid red; */
    justify-content: center;
  }
  .card-container-confirm {
    /* border: 1px solid purple; */
    min-height: 32rem;
    max-height: 36rem;
    overflow-y: hidden;
  }
}
